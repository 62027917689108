import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_watch_tutorial = _resolveComponent("tm-watch-tutorial")!
  const _component_services_byoc = _resolveComponent("services-byoc")!
  const _component_services_byoc_faq = _resolveComponent("services-byoc-faq")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-top-right": _withCtx(() => [
      _renderSlot(_ctx.$slots, "page-content-top-right", {}, () => [
        _createVNode(_component_tm_watch_tutorial)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_services_byoc),
      _createVNode(_component_services_byoc_faq, { class: "mt-10" })
    ]),
    _: 3
  }))
}