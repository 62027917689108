
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ServicesByocFaq from '@/components/pages/services/byoc/ServicesByocFaq.vue'
import ServicesByoc from '@/components/pages/services/byoc/ServicesByoc.vue'
import TmWatchTutorial from '@/components/shared/TmWatchTutorial.vue'

export default defineComponent({
  components: {
    TmWatchTutorial,
    PageContent,
    ServicesByocFaq,
    ServicesByoc,
  },
})
