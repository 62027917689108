
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmButton,
    TmEmptyState,
  },
  setup() {
    const { isEmptyMode } = useModes()

    return {
      isEmptyMode,
    }
  },
})
